<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Molecule Snapshot</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!-- <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="mb-0 text-xs">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.value }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCartPercent, mdiTrendingUp, mdiDotsVertical, mdiFormatListNumbered, mdiPointOfSale, mdiCartArrowUp } from '@mdi/js'
import { useDataStore } from '@/stores/Data'
import { useFilterStore } from '@/stores/FilterStore'
import { ref } from 'vue'
import { currencyConvert } from '@/util/functions'

export default {
    setup() {
        const dataStore = useDataStore()
        const filterStore = useFilterStore()
        const statisticsData = ref([
            {
                title: 'Total Sales',
                value: 0,
            },
            {
                title: 'Taro Sales',
                value: 1,
            },
            {
                title: 'Drug Rank',
                value: 2,
            },
            {
                title: 'Market Share',
                value: 3,
            },
        ])

        const resolveStatisticsIconVariation = data => {
            if (data === 'Total Sales') return { icon: mdiPointOfSale, color: 'primary' }
            if (data === 'Taro Sales') return { icon: mdiCartArrowUp, color: 'success' }
            if (data === 'Drug Rank') return { icon: mdiFormatListNumbered, color: 'warning' }
            if (data === 'Market Share') return { icon: mdiCartPercent, color: 'info' }

            return { icon: mdiAccountOutline, color: 'success' }
        }

        return {
            currencyConvert,
            filterStore,
            dataStore,
            statisticsData,
            resolveStatisticsIconVariation,

            // icons
            icons: {
                mdiDotsVertical,
                mdiTrendingUp,
                mdiAccountOutline,
                mdiFormatListNumbered,
                mdiCartPercent,
                mdiPointOfSale,
                mdiCartArrowUp
            },
        }
    },
    created() {
        this.$root.$on('refreshChart', async () => {
            await this.dataStore.setMoleculeSnapshot(this.filterStore.getFilterQuery)
            this.updateDate()
        })
    },
    async mounted() {
        await this.dataStore.setMoleculeSnapshot(this.filterStore.getFilterQuery)
        this.updateDate()

        // this.$root.$on('destroyChart', async () => {
        //     this.dataStore.resetMoleculeSnapshot()
        // })
    },
    destroyed() {
        this.$root.$off('refreshChart')

        // this.$root.$off('destroyChart')
    },
    methods: {
        updateDate() {
            this.statisticsData = [
                {
                    title: 'Total Sales',

                    // value: currencyConvert(this.dataStore.moleculeSnapshot[0].totalSalesNum),
                    value: currencyConvert(this.dataStore.getDataObject.totalSalesNum),

                },

                {
                    title: 'Taro Sales',
                    value: currencyConvert(this.dataStore.getDataObject.totalTaroSales),
                },
                {
                    title: 'Drug Rank',
                    value: this.dataStore.getDrugRank,
                },
                {
                    title: 'Market Share',
                    value: `${this.dataStore.getTaroShare }%`,
                },
            ]
        },
    },
}
</script>
